/** Text completion prompts */

import songImage from './song-image.js'
import songLine from './song-line.js'
import songSection from './song-section.js'

export const CompletionPrompts = {
  songSection,
  songLine,
  songImage,
}
