/** Select an entity dialog */

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material'

import { graphHooks } from '@tunasong/graph-lib/react'
import type { FilterFunc } from '@tunasong/models'
import { isTopLevelEntity } from '@tunasong/models'
import type { Entity, Persisted } from '@tunasong/schemas'
import { EntityTree } from '@tunasong/ui-lib'
import type { FC, MouseEvent } from 'react'
import { useCallback, useState } from 'react'

interface SelectEntityDialogProps {
  open: boolean
  excludeIds?: string[]
  entities?: Persisted<Entity>[]
  filter?: FilterFunc
  title?: string
  onClose(selected?: Persisted<Entity>): void
}

export const EntitySelectDialog: FC<SelectEntityDialogProps> = props => {
  const { open, filter = isTopLevelEntity, title = 'Select an entity', excludeIds = [], entities, onClose } = props
  const [selected, setSelected] = useState<Persisted<Entity>>()
  const { entities: allEntities } = graphHooks.useEntities({ root: true })

  const handleCancel = useCallback(() => {
    setSelected(undefined)
    onClose()
  }, [onClose])

  const handleOK = useCallback(() => {
    onClose(selected)
  }, [onClose, selected])

  /** For root we use a dummy object */
  const handleSelectRoot = useCallback(() => {
    const sel = { id: 'ROOT', name: 'Top' }
    setSelected(sel as never)
  }, [])

  const handleDialogClick = (ev: MouseEvent) => {
    // We don't propagate clicks to the parent
    ev.stopPropagation()
  }

  return (
    <Dialog title={title} open={open} maxWidth="sm" fullWidth={true} onClick={handleDialogClick}>
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <EntityTree
          rootEntities={entities ?? allEntities}
          filter={filter}
          selected={selected}
          disableIds={excludeIds}
          onSelect={setSelected}
          onSelectRoot={handleSelectRoot}
        />
      </DialogContent>
      <DialogActions>
        {selected ? <Typography variant="subtitle1"> {selected.name}</Typography> : null}
        <Button disabled={!selected} onClick={handleOK}>
          OK
        </Button>
        <Button onClick={handleCancel}>Cancel</Button>
      </DialogActions>
    </Dialog>
  )
}
