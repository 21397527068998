import { encodeMetadata } from '@tunasong/autoform'
import { z } from 'zod'
import { aiModelsSchema } from './providers.js'

const personalitySchema = z.enum(['neutral', 'friendly', 'professional', 'witty', 'creative'])

export const aiContexts = ['global', 'song:section', 'song:line', 'song:image', 'advisor'] as const

type AIContextName = (typeof aiContexts)[number]

type AIContextDescription = {
  label: string
  description: string
}

export const aiContextDescriptions = {
  global: {
    label: 'Global',
    description: 'Global settings for all AI contexts',
  },
  'song:section': {
    label: 'Song Section',
    description: 'Settings for generating song sections',
  },
  'song:line': {
    label: 'Song Line',
    description: 'Settings for generating song lines',
  },
  'song:image': {
    label: 'Song Image',
    description: 'Settings for generating images from song lyrics',
  },
  advisor: {
    label: 'Musical Advisor',
    description: 'Settings for the musical advisor AI',
  },
} satisfies Record<AIContextName, AIContextDescription>

const aiContextSchema = z.enum(aiContexts).describe('The context for the AI')

/** Settings for a particular context */
export const aiContextSettingsSchema = z
  .object({
    model: aiModelsSchema.optional(),
    // General system prompt for the context
    systemPrompt: z
      .string()
      .describe(
        encodeMetadata({
          label: 'System Prompt',
          multiline: true,
        })
      )
      .optional(),

    // Style of the output, e.g., impressionist, modern, etc.
    defaultPrompt: z
      .string()
      .describe(
        encodeMetadata({
          label: 'Default prompt text for non-interactive AI',
          multiline: true,
        })
      )
      .optional(),

    // Personality of the AI
    personality: personalitySchema.optional(),

    // Style of the output, e.g., impressionist, modern, etc.
    textStyle: z
      .string()
      .describe(
        encodeMetadata({
          label: 'Style for generated text',
          multiline: true,
        })
      )
      .optional(),

    // Style of the output, e.g., impressionist, modern, etc.
    imageStyle: z
      .string()
      .describe(
        encodeMetadata({
          label: 'Style of generated images',
          multiline: true,
        })
      )
      .optional(),

    //
  })
  .describe('AI settings for a context')

export type AIContextSettings = z.infer<typeof aiContextSettingsSchema>

export type AIContext = z.infer<typeof aiContextSchema>

// Basic schema for the AI Core Message
export const aiBasicCoreMessageSchema = z.object({
  role: z.enum(['system', 'user', 'assistant', 'tool']),
  // The text content of the message
  content: z.string(),
})
export type AIBasicCoreMessage = z.infer<typeof aiBasicCoreMessageSchema>

// Full AI Configuration
export const aiConfigSchema = z.object({
  // Room for more configuration settings here
  contexts: z.record(aiContextSchema, aiContextSettingsSchema),
})

export type AIConfig = z.infer<typeof aiConfigSchema>
