import type { Theme } from '@mui/material'
import {
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  MenuItem,
  MenuList,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import type { SxProps } from '@mui/system'
import { Box } from '@mui/system'
import { useMixerMenuItem, useTunerMenuItem } from '@tunasong/audio-ui'
import { graphHooks } from '@tunasong/graph-lib/react'
import { Favorite, Home, Labs, Search } from '@tunasong/icons'
import { useGuitarChordMenuItem, useKeyboardMenuItem } from '@tunasong/music-ui'
import type { Entity, Persisted } from '@tunasong/schemas'
import { useSearchDialog } from '@tunasong/search'
import {
  EntityListItem,
  ShortcutBox,
  UserAvatar,
  VBox,
  shortcuts,
  useCurrentUser,
  useFavorites,
  useNavigate,
  useNavigateToEntity,
} from '@tunasong/ui-lib'
import type { FC } from 'react'

export interface AppSideBarProps {
  entity?: Persisted<Entity>
}

export const AppSideBar: FC<AppSideBarProps> = props => {
  const { entity } = props

  const navigateToEntity = useNavigateToEntity()

  const { favorites } = useFavorites()
  const { profile } = graphHooks.useProfile()
  const { userId, name: authName } = useCurrentUser()
  const name = profile?.name ?? authName

  const theme = useTheme()
  const avatarSize = useMediaQuery(theme.breakpoints.down('sm')) ? 'tiny' : 'small'

  const { dialog: searchDialog, setOpen: setSearchOpen, open: searchOpen } = useSearchDialog(entity)

  const { dialog: mixerDialog, menuItem: mixerMenuItem } = useMixerMenuItem()
  const { dialog: tunerDialog, menuItem: tunerMenuItem } = useTunerMenuItem()
  const { dialog: keyboardDialog, menuItem: keyboardMenuItem } = useKeyboardMenuItem()
  const { dialog: guitarDialog, menuItem: guitarMenuItem } = useGuitarChordMenuItem()

  const navigate = useNavigate()
  const menuItemSx: SxProps<Theme> = {
    '&:hover': {
      backgroundColor: theme => theme.palette.action.hover,
    },
  }

  // const handleLayout = (layoutName: string) => (ev: MouseEvent) => {
  //   ev.preventDefault()
  //   setLayout(layoutName)
  // }

  return (
    <VBox
      sx={{
        overflow: 'auto',
        mt: 1,
        backgroundColor: theme => theme.vars.palette.layers,
        borderRight: theme => `solid 1px ${theme.palette.divider}`,
        zIndex: 1,
        width: 192,
      }}
    >
      <Box sx={{ ml: 2, display: 'flex' }}>
        <UserAvatar size={avatarSize} userId={userId} aria-label="user" />
        <Typography variant="caption" sx={{ ml: 1, mt: 1, mb: 2 }}>
          {name}
        </Typography>
      </Box>

      <MenuList sx={{ width: '100%', display: 'flex', flexDirection: 'column', flex: 1 }}>
        <MenuItem onClick={() => navigate('/app')} sx={menuItemSx} selected={location.pathname === '/app'}>
          <ListItemIcon>
            <Home />
          </ListItemIcon>
          <ListItemText>Home</ListItemText>
        </MenuItem>
        <MenuItem onClick={() => setSearchOpen(true)} sx={menuItemSx} selected={searchOpen}>
          <ListItemIcon>
            <Search />
          </ListItemIcon>
          <ListItemText>Search</ListItemText>
          <ListItemSecondaryAction>
            <ShortcutBox shortcut={shortcuts.Search} showDescription={false} />
          </ListItemSecondaryAction>
        </MenuItem>
        <MenuItem
          onClick={() => navigate('/app/favorites')}
          selected={location.pathname === '/app/favorites'}
          sx={menuItemSx}
        >
          <ListItemIcon>
            <Favorite />
          </ListItemIcon>
          <ListItemText>Favorites</ListItemText>
        </MenuItem>

        <MenuItem disabled>
          <Typography variant="caption">Tools</Typography>
        </MenuItem>

        {mixerMenuItem}
        {tunerMenuItem}
        {keyboardMenuItem}
        {guitarMenuItem}
        <MenuItem onClick={() => navigate('/app/labs')}>
          <ListItemIcon>
            <Labs />
          </ListItemIcon>
          <ListItemText>Labs</ListItemText>
        </MenuItem>

        <MenuItem disabled sx={{ flex: 1 }} />

        <MenuItem disabled sx={{ display: 'flex', justifyContent: 'center' }}>
          Favorites
        </MenuItem>

        {favorites.map(favorite => (
          <EntityListItem
            key={favorite.id}
            selected={entity?.id === favorite.id}
            element={favorite}
            orientation="vertical"
            size="small"
            actions={false}
            hideSubtitle={true}
            iconStyle={'icon'}
            onClick={() => navigateToEntity(favorite)}
          />
        ))}
        {favorites.length === 0 ? (
          <MenuItem disabled>
            <ListItemText>
              <Typography variant="caption">No favorites</Typography>
            </ListItemText>
          </MenuItem>
        ) : null}
      </MenuList>

      {searchDialog}
      {mixerDialog}
      {tunerDialog}
      {keyboardDialog}
      {guitarDialog}
    </VBox>
  )
}
