import { z } from 'zod'
import { aiFunction } from './functions.schema.js'
import { aiModelsSchema, aiProviderSchema } from './providers.js'

export const chatMessage = z.object({
  role: z.enum(['user', 'system', 'assistant']),
  content: z.string(),

  function_call: z
    .object({
      name: z.string(),
      arguments: z.string(),
    })
    .optional(),
})
export type ChatMessage = z.infer<typeof chatMessage>

/** For use when streaming message to ensure we know when messages are complete */
export const chatMessageStream = chatMessage.extend({
  pending: z.boolean().optional().describe('Whether the completion is still pending (incremental load)'),
})
export type ChatMessageStream = z.infer<typeof chatMessageStream>

/** @todo move this to the ai-lib package */
export const chatPayloadSchema = z.object({
  temperature: z.number().default(0.5).optional(),
  providerName: aiProviderSchema.default('openai'),
  messages: z.array(chatMessage),
  functions: z.array(aiFunction).optional(),
  model: aiModelsSchema.optional(),
  parentChatId: z.string().optional().describe('If specified, add a completed comment to the specified chat'),
})

export type ChatPayload = z.infer<typeof chatPayloadSchema>

/** @todo this is not correct with the new AI SDK */
export const chatResponseSchema = z.object({
  completions: z.array(
    z.object({
      message: chatMessage,
      finish_reason: z.string(),
    })
  ),
  text: z.string(),
  tokens: z.number(),
})
export type ChatResponse = z.infer<typeof chatResponseSchema>
