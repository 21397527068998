import { createAnthropic } from '@ai-sdk/anthropic'
import { createGoogleGenerativeAI } from '@ai-sdk/google'
import { createOpenAI } from '@ai-sdk/openai'
import { z } from 'zod'

/** AI providers and their required env vars */

const supportedModels = [
  'gpt-4',
  'gpt-4o',
  'gpt-4o-mini',
  'gpt-o1',
  'gpt-o1-mini',
  'o1',
  'o3-mini',
  'gpt-4-audio-preview',
  'claude-3-5-sonnet-20241022',
  'claude-3-5-haiku-20241022',
  'claude-3-opus-20240229',
  'gemini-2.0-flash-exp',
] as const
export type SupportedModel = (typeof supportedModels)[number]

const supportedModelsByProvider: Record<string, [SupportedModel, ...SupportedModel[]]> = {
  openai: ['gpt-4', 'gpt-4o', 'gpt-4o-mini', 'gpt-o1', 'gpt-o1-mini', 'gpt-4-audio-preview', 'o1', 'o3-mini'],
  anthropic: ['claude-3-5-sonnet-20241022', 'claude-3-5-haiku-20241022', 'claude-3-opus-20240229'],
  gemini: ['gemini-2.0-flash-exp'],
}

/** Future proofing to ensure that we can calculate aiTokens from tokens used by each model */
export const aiModelTokenCost: Record<SupportedModel, number> = {
  'gpt-4': 1,
  'gpt-4o': 2,
  'gpt-4o-mini': 0.5,
  'gpt-o1': 4,
  'gpt-4-audio-preview': 4,
  'gpt-o1-mini': 1,
  o1: 4,
  'o3-mini': 1,
  'claude-3-5-sonnet-20241022': 2,
  'claude-3-5-haiku-20241022': 1,
  'claude-3-opus-20240229': 1,
  'gemini-2.0-flash-exp': 1,
}

export const aiModelsSchema = z.enum(supportedModels)

export const aiProviderSchema = z.enum(['openai', 'anthropic', 'gemini'])

export interface AIProviderSpec {
  // Path to the secret containing the API key in Parameter Store
  apiKeySecret: string

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  languageModels: z.ZodEnum<any>
}

export type AIProvider = z.infer<typeof aiProviderSchema>

const providerSpecs = {
  openai: {
    apiKeySecret: '/tunasong/openai/api-key',
    languageModels: z.enum(supportedModelsByProvider.openai),
    createProvider: (options: Parameters<typeof createOpenAI>[0]) =>
      createOpenAI({ ...options, compatibility: 'strict' }),
  },
  anthropic: {
    apiKeySecret: '/tunasong/anthropic/api-key',
    languageModels: z.enum(supportedModelsByProvider.anthropic),
    createProvider: createAnthropic,
  },
  gemini: {
    apiKeySecret: '/tunasong/google/gemini-api-key',
    languageModels: z.enum(supportedModelsByProvider.gemini),
    createProvider: createGoogleGenerativeAI,
  },
} satisfies Record<AIProvider, AIProviderSpec & Record<string, unknown>>

export const getProviderByModel = (model: SupportedModel) => {
  for (const provider in supportedModelsByProvider) {
    if (supportedModelsByProvider[provider].includes(model)) {
      return provider as AIProvider
    }
  }
  return null
}

export const getTokenCostByModel = (model: SupportedModel) => aiModelTokenCost[model]

export const getProviderSpec = (provider: AIProvider) => providerSpecs[provider]

export const getProviderApiKeySecret = (provider: AIProvider) => providerSpecs[provider]?.apiKeySecret
