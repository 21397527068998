import type { ListItemProps } from '@mui/material'
import { Divider, ListItemIcon, MenuItem } from '@mui/material'
import { ElectricGuitar } from '@tunasong/icons'
import type { GuitarLib } from '@tunasong/music-lib'
import type { Scale } from '@tunasong/schemas'
import { Dialog } from '@tunasong/ui-lib'
import type { MouseEvent } from 'react'
import { useState } from 'react'
import { ScaleSelect } from '../../scale/scale-select.js'
import { FindGuitarChord } from './find-chord.js'

interface GuitarChorddMenuItemProps extends Omit<ListItemProps, 'button'> {
  onClick?: () => void
}

export const useGuitarChordMenuItem = (props: GuitarChorddMenuItemProps = {}) => {
  const { onClick, ...restProps } = props

  const [scale, setScale] = useState<Scale | null>({ root: 'C', type: 'major' })
  const [, setSelected] = useState<GuitarLib.GuitarChord>()

  const [show, setShow] = useState(false)

  const handleShow = (ev: MouseEvent) => {
    ev.preventDefault()
    setShow(true)
    if (onClick) {
      onClick()
    }
  }

  const handleHide = () => {
    setShow(false)
  }

  return {
    dialog: (
      <Dialog open={show} onClose={handleHide} title="Chord Finder" fullWidth={true} maxWidth="md">
        <ScaleSelect value={scale} onChange={setScale} />

        <Divider sx={{ my: 2 }}>Click to select fret positions</Divider>

        <FindGuitarChord onSelected={setSelected} scale={scale} />
      </Dialog>
    ),
    menuItem: (
      <MenuItem title="Guitar chord" color="inherit" onClick={handleShow} {...restProps}>
        <ListItemIcon>
          <ElectricGuitar />
        </ListItemIcon>
        Guitar Chord
      </MenuItem>
    ),
  }
}
