import { SystemTags } from '@tunasong/models'
import type { EntityType } from '@tunasong/schemas'
import { useMemo } from 'react'
import { allQueries } from './queries/index.js'
import { useAdvancedSearch } from './search.hook.js'
import { graphHooks } from '@tunasong/graph-lib/react'

/** Load templates in two steps - first search for them, then load them */
export const useTemplates = (entityType?: EntityType) => {
  const query = useMemo(() => (entityType ? allQueries.templates({ query: entityType }) : undefined), [entityType])
  const { results, ...restProps } = useAdvancedSearch(query, {
    includeSys: true,
    queryType: 'templates',
  })

  const ids = results.map(result => result.id)
  const { entities } = graphHooks.useEntitiesById(ids)
  const mappedResults = useMemo(
    () =>
      entities.map(result => ({
        ...result,
        id: undefined,
        tags: result.tags?.filter(tag => tag !== SystemTags.Template),
      })),
    [entities]
  )
  return {
    ...restProps,
    results: mappedResults,
  }
}
