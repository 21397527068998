import { canWriteEntity } from '@tunasong/models'
import { useYDoc } from '@tunasong/plugin-lib'
import { graphHooks } from '@tunasong/graph-lib/react'
import { isEntity, isPersisted } from '@tunasong/schemas'
import { getProfileColor, PulseProgress, useCurrentUser } from '@tunasong/ui-lib'
import { useWebSocket } from '@tunasong/ws'
import { useMemo } from 'react'
import type { FC } from 'react'
import invariant from 'tiny-invariant'
import { CollabWebsocketEditor } from './collab-editor-websocket.js'
import type { CollabWebSocketEditorProps } from './collab-editor-websocket.js'

/** Inject Y.Doc from Redux */
export type CollabWebsocketEditorYDocProps = Omit<
  CollabWebSocketEditorProps,
  'doc' | 'name' | 'color' | 'webSocket' | 'awareness'
>
export const CollabWebsocketEditorYDoc: FC<CollabWebsocketEditorYDocProps> = props => {
  const { element: entity, ...restProps } = props

  invariant(isPersisted(entity) && isEntity(entity), 'CollabWebsocketEditorYDoc requires a persisted entity')

  const { userId } = useCurrentUser()
  /** Set readOnly if we do not have write access */
  const readOnly = useMemo(() => !canWriteEntity(userId ?? '', entity), [entity, userId])

  const { doc, isFetching } = useYDoc(entity)
  const webSocket = useWebSocket()
  const { profile } = graphHooks.useProfile()
  const { color } = useMemo(() => getProfileColor(profile), [profile])

  return entity && doc ? (
    <CollabWebsocketEditor
      key={entity.id}
      {...restProps}
      readOnly={Boolean(readOnly || props.readOnly)}
      name={profile?.name ?? profile?.email ?? 'Anonymous'}
      color={color}
      webSocket={webSocket}
      doc={doc}
      element={entity}
    />
  ) : isFetching ? (
    <PulseProgress placeholder="Loading..." />
  ) : null
}

export default CollabWebsocketEditor
