import { Box, IconButton, Typography } from '@mui/material'
import { AudioRecorder, Waveform } from '@tunasong/audio-ui'
import { graphHooks } from '@tunasong/graph-lib/react'
import { Delete } from '@tunasong/icons'
import type { SortOptions } from '@tunasong/models'
import { dayjs } from '@tunasong/models'
import type { AudioMedia, Entity, Persisted } from '@tunasong/schemas'
import { isAudio } from '@tunasong/schemas'
import type { SidebarComponent } from '@tunasong/ui-lib'
import { VBox, useConfirmDeleteDialog, useEntitySort, useUploadStoredEntity } from '@tunasong/ui-lib'
import type { FC } from 'react'
import { useCallback } from 'react'

const sortOptions = { sortBy: 'updatedAt', order: 'desc' } satisfies SortOptions

export const SidebarRecord: FC<SidebarComponent> = ({ entity }) => {
  const { entities: clips } = graphHooks.useEntityChildren<AudioMedia>({ parentId: entity.id, filter: isAudio })
  const audioClips = useEntitySort(clips, sortOptions)
  const [deleteEntity] = graphHooks.useEntityDelete()
  const entityId = entity.id

  const { ConfirmDeleteDialog, setConfirmItemHandler } = useConfirmDeleteDialog()

  const { uploadNewEntity } = useUploadStoredEntity()
  const handleComplete = useCallback(
    (blob: Blob) => {
      const name = `${dayjs().format('YYYY-MM-DD-HH-mm')}`
      uploadNewEntity(name, entityId, blob)
    },
    [entityId, uploadNewEntity]
  )

  return (
    <VBox sx={{ overflow: 'hidden' }}>
      <Box>
        <AudioRecorder onComplete={handleComplete} />
      </Box>
      <Typography variant="h6" gutterBottom>
        Clips
      </Typography>
      <Box sx={{ flex: 1, overflow: 'auto' }}>
        {audioClips.length > 0 ? (
          audioClips.map(clip => (
            <Box sx={{ position: 'relative' }} key={clip.id}>
              <IconButton
                sx={{ position: 'absolute', right: 0, top: 4, zIndex: 1, opacity: 0.5, ':hover': { opacity: 1 } }}
                color="error"
                onClick={setConfirmItemHandler(clip)}
              >
                <Delete />
              </IconButton>
              <Waveform key={clip.id} media={clip} autoStop={true} sx={{ paddingTop: 2 }} renderPeaks={true} />
            </Box>
          ))
        ) : (
          <Typography variant="caption">No clips</Typography>
        )}
      </Box>
      <ConfirmDeleteDialog onConfirm={entity => deleteEntity({ entity: entity as Persisted<Entity> })} />
    </VBox>
  )
}
