import { Alert, Button, Divider } from '@mui/material'
import { graphHooks } from '@tunasong/graph-lib/react'
import { Play } from '@tunasong/icons'
import { capitalize } from '@tunasong/models'
import { useCommandSpecRunner } from '@tunasong/plugin-lib'
import type { EntityEvent, EventCommands } from '@tunasong/schemas'
import type { SidebarComponent } from '@tunasong/ui-lib'
import { Link, useEventCommands, useProfileCommands } from '@tunasong/ui-lib'
import type { FC } from 'react'
import { EventCommandsList } from '../commands/event-commands-list.js'
interface SidebarEventsProps extends SidebarComponent {}

export const SidebarEvents: FC<SidebarEventsProps> = props => {
  const { entity } = props
  const update = graphHooks.useEntityUpdate()

  // this will include the event commands defined in the profile
  const allEventCommands = useEventCommands(entity)
  const runner = useCommandSpecRunner()

  const { eventCommands = {} } = entity
  const { eventCommands: profileEventCommands } = useProfileCommands(entity.type)
  const handleUpdate = (eventCommands: EventCommands) => {
    update(entity.id, {
      eventCommands,
    })
  }
  const handleRun = (eventType: EntityEvent) => () => {
    if (!runner) {
      return
    }
    const commands = allEventCommands[eventType] ?? []
    for (const command of commands) {
      runner({ spec: command, targetEntityId: entity.id })
    }
  }

  return (
    <>
      <EventCommandsList eventCommands={eventCommands} onChange={handleUpdate} />
      <Divider>Run for all {capitalize(entity.type ?? '')}s</Divider>
      <Alert severity="info">
        Configured in your <Link to={'/app/profile?tab=events'}>profile</Link>
      </Alert>
      <EventCommandsList eventCommands={profileEventCommands} />
      <Divider sx={{ m: 2 }} />
      {Object.keys(allEventCommands).map(eventType => (
        <Button
          key={`run-${eventType}`}
          disabled={!runner || (allEventCommands[eventType as EntityEvent] ?? []).length === 0}
          onClick={handleRun(eventType as EntityEvent)}
          startIcon={<Play />}
        >
          Run {eventType}
        </Button>
      ))}
      ,
    </>
  )
}
