import { Box, Tab, Tabs } from '@mui/material'
import { AutoForm } from '@tunasong/autoform'
import type { FC } from 'react'
import { useState } from 'react'
import type { AIConfig, AIContext, AIContextSettings } from '../schema/ai-schema.js'
import { aiContexts, aiContextDescriptions, aiContextSettingsSchema } from '../schema/ai-schema.js'

interface AIConfigProps {
  defaultValue?: AIConfig
  onChange?: (config: AIConfig) => void
}

export const AIConfigForm: FC<AIConfigProps> = props => {
  const { defaultValue, onChange } = props

  const [tab, setTab] = useState<AIContext>('global')
  const [config, setConfig] = useState<AIConfig>(defaultValue ?? { contexts: {} })

  const contexts = config.contexts

  const handleSubmit = (context: AIContext, values?: AIContextSettings) => {
    const newConfig = { contexts: { ...config.contexts, [context]: values } }
    setConfig(newConfig)
    if (onChange) {
      onChange(newConfig)
    }
  }

  const selectedContext = contexts[tab] ?? {}

  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', height: '100%' }}>
      <Tabs value={tab} onChange={(_, value) => setTab(value)} orientation="vertical">
        {aiContexts.map(context => (
          <Tab key={context} label={aiContextDescriptions[context].label} value={context} />
        ))}
      </Tabs>

      <AutoForm
        key={tab}
        sx={{ flex: 1, ml: 2 }}
        schema={aiContextSettingsSchema}
        defaultValues={selectedContext}
        onSubmit={values => handleSubmit(tab, values)}
      />
    </Box>
  )
}

export default AIConfigForm
