import type { SectionType } from '@tunasong/models'
import { generatePromptSpec } from '../../generate-prompt.js'
import type { AIConfig } from '../../schema/ai-schema.js'

export default ({
  title,
  type,
  lyrics,
  userConfig,
}: {
  /** Title of song/document */
  title: string
  type: SectionType
  style?: string
  lyrics: string
  userConfig?: AIConfig
}) => {
  const context = 'song:section'
  return generatePromptSpec({
    promptText: `Write a ${type} for the "${title}" song. Include only the lyrics, no other text. The current lyrics are: \n\n ${lyrics}.`,
    context,
    userConfig: userConfig?.contexts[context],
  })
}
