import type { NoteName, NoteWithOctave, Scale, StandardNoteNameWithOctave } from '@tunasong/schemas'
import type { Chord, ChordVariant } from '../chord/chord-types.js'
import { getName } from '../chord/get-name.js'
import {
  getNoteByChromaticOffset,
  getNoteMIDINumber,
  getNoteOctaveByChromaticOffset,
  getPrevChromaticNotes,
  NoteLib,
  sortFn,
  uniqueFn,
} from '../note/index.js'
import type { ScaleDegree } from '../scale/index.js'
import { ScaleLib } from '../scale/index.js'
import type { ChordShape } from './chord-shapes.js'
import { CHORD_SHAPES, MUTED_STRING, OPEN_STRING } from './chord-shapes.js'
import type { Tuning } from './tuning.js'
import { GuitarTunings } from './tuning.js'

export type Fret = number | typeof OPEN_STRING | typeof MUTED_STRING
export type StringNumber = 1 | 2 | 3 | 4 | 5 | 6

export const getFingeringFromFrets = (frets: number[]) =>
  frets.map((f, idx) => ({ fret: f, str: idx + 1 })) as Fingering[]
/**
 * Return an array of fretted strings, where index 0 is string 1.
 * 0 is open string, -1 is muted string.
 */
export const getFretsFromFingering = (fingerings: Fingering[]) => {
  /** Start with all open strings */
  const frets: number[] = [0, 0, 0, 0, 0, 0]
  for (const { str, fret } of fingerings) {
    frets[str - 1] = fret
  }
  return frets
}

export interface Fingering {
  /**
   * From 1 = high E, to 6 = low E.
   */
  str: StringNumber
  /** The fret for the fingering.
   *  -1: Muted
   *   0: Open string
   * >=1: Fretted at fret
   */
  fret: Fret
}

export type FingeringWithMetadata = Fingering & {
  note: NoteName | null
} & (
    | { inScale: true; scaleDegree: ScaleDegree }
    | {
        inScale: false | undefined | null
        scaleDegree: null | undefined
      }
  )

export function isFingeringWithMetadata(f: Fingering | FingeringWithMetadata): f is FingeringWithMetadata {
  return 'note' in f && 'inScale' in f && 'fret' in f && 'str' in f
}

export const getFingeringWithMetadata = (fingering: Fingering[], scale?: Scale | null): FingeringWithMetadata[] =>
  fingering.map(f => {
    const note = getNoteFromFingering(f)
    const inScale = scale ? ScaleLib.noteInScale(scale, note) : undefined
    return {
      ...f,
      note,
      inScale,
      scaleDegree: inScale && scale ? ScaleLib.getDegree(scale, note) : undefined,
    } as FingeringWithMetadata
  })

export const removeMetadata = (fingering: Fingering[] | FingeringWithMetadata[]): Fingering[] =>
  fingering.map(f => ({
    str: f.str,
    fret: f.fret,
  }))

/** Get the note */
export const getNoteFromFingering = ({ str, fret }: Fingering, tuning = GuitarTunings.normal): NoteName | null => {
  if (fret === MUTED_STRING) {
    return null
  }
  const { note } = NoteLib.getNoteAndOctave(tuning[str])

  return getNoteByChromaticOffset(note, fret)
}

export const getNoteOctaveFromFingering = (
  { str, fret }: Fingering,
  tuning = GuitarTunings.normal
): StandardNoteNameWithOctave | null => {
  if (fret === MUTED_STRING) {
    return null
  }
  const note = tuning[str]

  return getNoteOctaveByChromaticOffset(note, fret)
}

export const getNotesFromFingering = (fingering: Fingering[]): NoteName[] =>
  fingering
    .map(f => getNoteFromFingering(f) as NoteName)
    .filter(Boolean)
    .filter(uniqueFn)
    .sort(sortFn)

/** @todo implement properly */
export const getFingering = (chord?: ChordVariant): Fingering[] | undefined => {
  if (!chord) {
    return
  }
  let c: Chord = { root: chord.root, variant: chord.variant }
  for (let fret = 0; fret < 12; fret++) {
    c = fret === 0 ? c : { root: getPrevChromaticNotes(c.root)[0], variant: c.variant }
    const name = getName(c) as ChordShape
    /** Do we have a shape for the chord? */
    const shape = CHORD_SHAPES[name]
    if (shape) {
      return barre(fret, name)
    }
  }
}

export const transpose = (fingerings: Fingering[], steps = 1) =>
  fingerings.map(f => ({
    ...f,
    fret: f.fret >= 0 ? f.fret + steps : f.fret,
  }))

export const barre = (fret: Fret, shape: ChordShape): Fingering[] => {
  const fingering = CHORD_SHAPES[shape] as Fingering[]
  if (!fingering) {
    return []
  }
  if (fret === 0) {
    return fingering
  }
  /** Transpose the chord */
  return transpose(fingering, fret)
}

export interface BarreRange {
  barre: number
  startString: number
  endString: number
}

/** Get the string range for a barre, or null if no barre is possible */
export const getBarreRange = (fingerings: Fingering[]) => {
  const hasOpenStrings = fingerings.filter(f => f.fret === OPEN_STRING).length > 0
  if (hasOpenStrings) {
    return null
  }
  /** Barre is the first fret of the fingerings and remove the muted and open string */
  const sorted = fingerings.sort((a, b) => (a.fret === b.fret ? 0 : a.fret < b.fret ? -1 : 1)).filter(f => f.fret > 0)
  const fingering = sorted[0]
  if (!fingering) {
    return null
  }
  const { fret } = fingering
  const candidates = sorted.filter(f => f.fret === fret)
  if (candidates.length < 2) {
    return null
  }
  /** Find start and end fret */
  const strings = candidates.map(c => c.str)
  const startString = Math.min.apply(null, strings)
  const endString = Math.max.apply(null, strings)
  const range: BarreRange = {
    barre: fret,
    startString,
    endString,
  }
  return range
}

/** Get the note ranges for a tuning */
export const getFretboardRange = (tuning = GuitarTunings.normal, frets = 20) => {
  const range: Record<
    number,
    { from: { note: NoteWithOctave; midiNumber: number }; to: { note: NoteWithOctave; midiNumber: number } }
  > = {}
  for (const [fret, note] of Object.entries(tuning)) {
    const toNote = getNoteOctaveByChromaticOffset(note, frets)
    const from = NoteLib.getNoteAndOctave(note)
    const fromMidi = getNoteMIDINumber(note)
    const to = NoteLib.getNoteAndOctave(toNote)
    const toMidi = getNoteMIDINumber(toNote)
    range[Number(fret)] = { from: { note: from, midiNumber: fromMidi }, to: { note: to, midiNumber: toMidi } }
  }
  return range
}

/** Get fingering from Notes */
export const getFingeringFromNotes = ({
  notes,
  tuning = GuitarTunings.normal,
  frets = 20,
}: {
  notes: StandardNoteNameWithOctave[]
  tuning: Tuning
  capo?: number
  frets?: number
}): Fingering[] => {
  // Make a map of the tuning and the note ranges, i.e., the starting note/octave and the ending note/octave
  const range = getFretboardRange(tuning, frets)

  const fingering: Fingering[] = []

  // For each string, find fingerings that match the note(s)
  for (const [str, noteWithOctave] of Object.entries(range)) {
    const strNum = Number(str) as StringNumber

    for (const candidateNote of notes) {
      const midiNumber = getNoteMIDINumber(candidateNote)

      const inRange = midiNumber >= noteWithOctave.from.midiNumber && midiNumber <= noteWithOctave.to.midiNumber
      if (!inRange) {
        continue
      }
      // The note is in range of the string, find the fingering
      const fret = midiNumber - noteWithOctave.from.midiNumber
      fingering.push({ str: strNum, fret })
    }
  }
  return fingering
}
