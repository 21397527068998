import type { AIConfig } from '../../schema/ai-schema.js'
import { generatePromptSpec } from '../../generate-prompt.js'

export default ({
  title,
  lyrics,
  userConfig,
}: {
  /** Title of song/document */
  title: string
  style?: string
  lyrics: string
  userConfig?: AIConfig
}) => {
  const promptText = `The song is called "${title}". The lyrics of the song are:

${lyrics}
`

  return generatePromptSpec({
    promptText,
    context: 'song:image',
    userConfig: userConfig?.contexts['song:image'],
  })
}
