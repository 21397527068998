import { Box, Button } from '@mui/material'
import { graphHooks } from '@tunasong/graph-lib/react'
import type { SearchSummary } from '@tunasong/models'
import type { Entity, Persisted, SearchFilter } from '@tunasong/schemas'
import { SearchSelectDialog } from '@tunasong/search'
import { EntityLink } from '@tunasong/ui-lib'
import { useState } from 'react'

interface EntitySelectProps {
  entityId?: string
  buttonText?: string
  onSelected?: (entityId: string) => void
  filter?: SearchFilter
}

export const EntitySelect = (props: EntitySelectProps) => {
  const { filter, buttonText = 'Select Entity...', entityId, onSelected } = props
  const { entity: target } = graphHooks.useEntity(entityId)

  const handleSelected = (result: SearchSummary | Persisted<Entity>) => {
    const { id } = result
    if (id) {
      onSelected?.(id)
    }
    setOpen(false)
  }

  const handleClose = () => setOpen(false)

  const [open, setOpen] = useState(false)

  return (
    <Box
      sx={{
        flex: 1,
        display: 'flex',
        overflow: 'hidden',
      }}
    >
      {target ? <EntityLink entity={target} /> : <Button onClick={() => setOpen(true)}>{buttonText}</Button>}

      <SearchSelectDialog onSelected={handleSelected} onCanceled={handleClose} open={open} defaultFilter={filter} />
    </Box>
  )
}
