import { Button, Grid, IconButton, Typography } from '@mui/material'
import { graphHooks } from '@tunasong/graph-lib/react'
import { Delete } from '@tunasong/icons'
import type { SearchSummary } from '@tunasong/models'
import type { EdgeType, Entity, Persisted, SearchFilter } from '@tunasong/schemas'
import { EntityCard, Pulse } from '@tunasong/ui-lib'
import type { FC } from 'react'
import { useCallback, useState } from 'react'
import { useRelatedContent } from './related-content.hook.js'
import { SearchResults } from './search-results.js'

/** View to manage related content */
/** Filter must be stable */
const filter = {
  types: ['audio'],
} satisfies SearchFilter

interface RelatedContentProps {
  entity: Persisted<Entity>
  relation?: EdgeType
  relationTitle?: string
}

export const RelatedContent: FC<RelatedContentProps> = props => {
  const { entity: source, relation = 'hasRecording', relationTitle = 'Recordings' } = props

  const [targetSelected, setTargetSelected] = useState<SearchSummary>()
  const { entity: target } = graphHooks.useEntity(targetSelected?.id)

  const { results } = useRelatedContent({
    entity: source,
    filter,
  })

  const {
    edgeEntities,
    createEdge,
    deleteEdge,
    hasLoaded: hasLoadedEdges,
    isLoading: isLoadingEdges,
    isCreating,
  } = graphHooks.useEdgeEntities({ sourceId: source?.id, relation })

  const linkedIds = edgeEntities.map(({ target }) => target?.id)
  const availableEntities = results.filter(r => !linkedIds.includes(r.id))
  const hasEdge = linkedIds.some(id => target?.id === id)

  const selectedIndex = availableEntities.findIndex(r => r.id === targetSelected?.id)

  const handleCreate = useCallback(async () => {
    if (!targetSelected) {
      return
    }
    await createEdge(targetSelected?.id)
    setTimeout(() => {
      setTargetSelected(availableEntities[1])
    }, 0)
  }, [availableEntities, createEdge, targetSelected])

  const handleDelete = useCallback((target: string) => () => deleteEdge(target), [deleteEdge])

  return (
    <>
      <Button
        disabled={isCreating || isLoadingEdges || !targetSelected || !hasLoadedEdges || hasEdge}
        onClick={handleCreate}
      >
        Link
      </Button>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Typography variant="h6">{relationTitle}</Typography>
          {isLoadingEdges && <Pulse />}
          {edgeEntities.map(({ target }) => (
            <EntityCard
              sx={{ my: 2 }}
              key={target.id}
              entity={target}
              showMenu={false}
              headerAction={
                <IconButton onClick={handleDelete(target.id)}>
                  <Delete />
                </IconButton>
              }
            />
          ))}
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="h6">Available Content</Typography>
          <SearchResults entities={availableEntities} selectedIndex={selectedIndex} onChange={setTargetSelected} />
        </Grid>
      </Grid>
    </>
  )
}

export default RelatedContent
